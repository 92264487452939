module.exports = {
    wildApricot: {
        help_AuthorizeApp: 'https://gethelp.wildapricot.com/en/articles/180#authorize',
        help_ConsentManagement: 'https://gethelp.wildapricot.com/en/articles/1999-consent-management',
        help_Registering: 'https://gethelp.wildapricot.com/en/articles/1902-getting-started-with-text-messaging',
        help_Registering_Consent: 'https://gethelp.wildapricot.com/en/articles/1902-getting-started-with-text-messaging#consent',
        help_Pricing: 'https://gethelp.wildapricot.com/en/articles/1892',
        help_ConsentDocument: 'https://gethelp.wildapricot.com/en/articles/1961-informed-consent-document',
        help_ApplicationSetup: 'https://gethelp.wildapricot.com/en/articles/135-membership-application-form#setup',
    },
    stripe: {
        pricePerMessage: 0.04,
        phoneRent: 6,
    },
};
