import React, {  useState } from 'react';

import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form, Button, Alert, Row, Col,
} from 'react-bootstrap';
import formUtils from '../../../utils/formUtils';
import LoadingIndicator from '../../LoadingIndicator';
import elevioHelpArticles from '../../../constants/elevio-help-articles';
import { openHelpArticle } from '../../HelpCenter/src/utils/elevio/util/elevio-functions';
import { getTwilioSubscription } from '../../../reducers/settingsReducer';
import { signUpSaveIntegrationOptions } from '../../../actions/signUpActions';
import { setupComplete } from '../../../actions/loginActions';

import twilioStatuses from '../../../constants/twilioStatuses';
import InfoTooltip from '../../Common/InfoTooltip';
import TermsOfUseCard from './TermsOfUseCard';

import { isValidUrl, ensureHttpsPrefix } from '../helpers/validators';

import './IntegrationOptions.scss';
import { externalResources } from 'mp-constants';

const IntegrationOptions = (
    {
        me,
        isStartup,
        isSettings,
        onNext,
        onPrev,
        actions,
        fetchError,
        saveError,
        fetchFieldsError,
        isFetching,
        isSaving,
        integrationOptions,
        fields,
        consentPages,
        primaryUrl,
    }) => {
    const dispatch = useDispatch();
    const { Status } = useSelector(getTwilioSubscription) || {};

    const error = fetchError || saveError || fetchFieldsError;
    const textFields = fields?.filter((opt) => opt.fieldType === 'Text');
    const boolFields = fields?.filter((opt) => opt.fieldType === 'RulesAndTerms' && !opt.adminOnly);

    const hasConsentPages = consentPages?.length > 0;

    const [data, setData] = useState(
        {
            consentUrl:  hasConsentPages ?
                integrationOptions.consentUrl || primaryUrl + consentPages[0].url : null,
            consentUrlId: hasConsentPages ?
                integrationOptions.consentUrlId || consentPages[0].id : 'other',
            consentUrlModule: hasConsentPages ?
                integrationOptions.consentUrlModule || consentPages[0].mainModule : null,
            customConsentUrl: integrationOptions.customConsentUrl || null,
            consentField: integrationOptions.consentField || null,
            hasChanges: false,
        });
    const [showOtherField, setOtherFieldVisible] = useState(
        !consentPages?.length > 0
        || integrationOptions.consentUrlId === 'other');

    // Validators
    const hasConsentFields = boolFields.length > 0;
    const hasConsentFieldSelected =
        (data.consentField && boolFields.find(f => f.id === data.consentField))
        || (integrationOptions.consentField && boolFields.find(f => f.id === integrationOptions.consentField));
    const isUnregistered = !me.account.hasSubscription && !me.account.hasTwilioAccount;
    const consentUrlEnabled = (isUnregistered || Status === twilioStatuses.campaign_rejected);
    const settingsChanged = data.hasChanges;
    const customConsentUrlInvalid =
        showOtherField && !isValidUrl(data?.customConsentUrl);

    const extractIntegrationOptions = () => {
        return {
            phoneField: data.phoneField || integrationOptions.phoneField || 'Phone',
            consentCheckMode: 'checkField',
            consentField: data.consentField || integrationOptions.consentField,
            consentUrl: data.consentUrlId === 'other' ?
                ensureHttpsPrefix(data.customConsentUrl)
                :
                ensureHttpsPrefix(data.consentUrl),
            consentUrlId: data.consentUrlId,
            consentUrlModule: data.consentUrlModule,
            customConsentUrl:
                ensureHttpsPrefix(data.customConsentUrl)
                || ensureHttpsPrefix(integrationOptions.customConsentUrl),
        };
    };

    const onFieldValChange = (event) => {
        const stateUpdate = formUtils.inputChangeToStateUpdate(event);
        setData({ ...data, ...stateUpdate, hasChanges: true });
    };

    const onConsentUrlValueChange = (event) => {
        if (event.target.value === 'other') {
            setOtherFieldVisible(true);
            setData({
                ...data,
                consentUrl: data.customConsentUrl,
                consentUrlId: 'other',
                consentUrlModule: null,
                hasChanges: true,
            });
        } else {
            if (event.target.value === '') {
                return;
            }
            const consentUrl = consentPages?.find(page => page.id === event.target.value).url;
            const consentModule = consentPages?.find(page => page.id === event.target.value).mainModule;
            setOtherFieldVisible(false);
            setData({
                ...data,
                consentUrl: primaryUrl + consentUrl,
                consentUrlId: event.target.value,
                consentUrlModule: consentModule,
                hasChanges: true,
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (isStartup) {
            dispatch(signUpSaveIntegrationOptions(extractIntegrationOptions()));
            dispatch(setupComplete());
        } else {
            if (hasConsentFields) {
                actions.saveIntegrationOptions(extractIntegrationOptions(), onNext);
                setData({ ...data, hasChanges: false, saveRequested: true });
            }
        }
    };

    const settingCannotSubmit =
        !hasConsentFields
        || !data.consentField
        || !hasConsentFieldSelected
        || !settingsChanged
        || (customConsentUrlInvalid && Status === twilioStatuses.campaign_rejected);

    const twilioFormCannotSubmit =
        !hasConsentFields
        || !data.consentField
        || !hasConsentFieldSelected
        || customConsentUrlInvalid;

    return (
        <div>
            <LoadingIndicator
                loading={isFetching}
                loadingText="Loading fields configuration from your WildApricot account"
            >
                {isStartup && (<TermsOfUseCard />)}
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Field with a mobile number</Form.Label>
                        <InfoTooltip
                            id='TNS-Settings_phone-info'
                            placement='top'
                        >
                            Select the contact field used for mobile numbers.
                        </InfoTooltip>
                        <Form.Control
                            onChange={onFieldValChange}
                            name="phoneField"
                            as="select"
                            type="select"
                            value={data.phoneField || integrationOptions.phoneField || 'Phone'}
                        >
                            {textFields.map((opt) => (<option key={opt.id} value={opt.id}>{opt.title}</option>))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>How to check consent?</Form.Label>
                        <InfoTooltip
                            id='TNS-Settings_consent-field'
                            placement='right'
                        >
                            <p>
                                US law and mobile service carrier regulations require organizations to obtain
                                explicit user consent before sending SMS messages. The field you select here
                                will be used by
                                {' '}
                                <i>WildApricot&apos;s text messaging</i>
                                {' '}
                                to verify recipient consent. This field should be a contact field of type
                                <i> Rules and terms </i> on a form that is publicly accessible (on a membership
                                application or an event registration, for example) and easily verified.
                                {' '}
                                <Button
                                    style={ { display: 'contents' } }
                                    variant="link"
                                    className="px-0"
                                    onClick={() =>
                                        openHelpArticle(elevioHelpArticles.TNS.GETTING_STARTED.id)}
                                >
                                    <b>Learn more</b>
                                </Button>
                            </p>
                        </InfoTooltip>

                        <Form.Control
                            onChange={onFieldValChange}
                            hidden={!boolFields.length}
                            isInvalid={data.consentField === ''}
                            id="consentField"
                            name="consentField"
                            as="select"
                            type="select"
                            value={data.consentField || integrationOptions.consentField || 'all'}
                            className="my-2"
                        >
                            {(!integrationOptions.consentField ||
                                !boolFields.find(f => f.id === integrationOptions.consentField)) ?
                                (<option key={''} value={''}>Select field</option>) : null
                            }
                            {boolFields.map((opt) => (<option key={opt.id} value={opt.id}>{opt.title}</option>))}
                        </Form.Control>
                        {boolFields.length > 0 && data.consentField === '' &&
                            <Form.Control.Feedback type="invalid">
                                Please select a consent field
                            </Form.Control.Feedback>
                        }
                        <div className="mimicFormControlReadOnly mt-2" hidden={boolFields.length > 0}>
                            No fields of type
                            {' '}
                            <b>Rules and terms </b>
                            found. Please define a consent field within your WildApricot account to continue setup.
                        </div>
                        <Form.Label>Consent URL</Form.Label>
                        {
                            <InfoTooltip
                                id='TNS-Settings_consent-url'
                                placement='right'
                            >
                                <p>
                                    Enter the URL of your website's membership application form 
                                    (such as, "yourwebsite.wildapricot.com/applynow"), 
                                    where users must check a consent field to agree to your policies.
                                    Do not enter the URL that takes users directly to your organization's 
                                    policies page. 
                                    For more information, see {' '}
                                    <a href={externalResources.wildApricot.help_ConsentManagement} target="_blank" 
                                        rel="noopener noreferrer">Consent management</a>.
                                </p>
                            </InfoTooltip>
                        }
                        <Form.Control
                            onChange={onConsentUrlValueChange}
                            disabled={!consentUrlEnabled}
                            id="consentUrl"
                            name="consentUrl"
                            as="select"
                            type="select"
                            value={data.consentUrlId || integrationOptions.consentUrlId}
                            className="my-2"
                        >
                            {consentPages?.map((page) => (
                                <option key={page.id} value={page.id}>
                                    {page.title}
                                    {' ('}
                                    {primaryUrl}
                                    {page.url}
                                    {')'}
                                </option>
                            ))}
                            <option key='other' value='other'>Other (custom consent URL)</option>
                        </Form.Control>
                        {showOtherField && (
                            <>
                                <Form.Label>Enter custom consent URL </Form.Label>
                                <Form.Control
                                    isInvalid={!isStartup &&
                                        (customConsentUrlInvalid || Status === twilioStatuses.campaign_rejected)}
                                    onChange={onFieldValChange}
                                    disabled={!consentUrlEnabled}
                                    id="customConsentUrl"
                                    name="customConsentUrl"
                                    as="input"
                                    type="input"
                                    value={data.customConsentUrl}
                                    className="my-2"
                                />
                            </>
                        )}
                        {!isStartup && customConsentUrlInvalid &&
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid URL
                            </Form.Control.Feedback>
                        }
                        {isSettings && Status === twilioStatuses.campaign_rejected &&
                            <Form.Control.Feedback type="invalid">
                                Please verify consent URL
                            </Form.Control.Feedback>
                        }
                    </Form.Group>

                    <Row>
                        <Col xs={12} md={8}>
                            <Alert className="mt-3" variant="danger" hidden={!error}>
                                {error}
                            </Alert>
                        </Col>
                    </Row>
                    <div className={`d-flex ${!isStartup && 'justify-content-between'}`}>
                        {isSettings &&
                            <div>
                                <Alert className="mt-3" variant="danger" hidden={!error}>
                                    {error}
                                </Alert>

                                <Alert variant="success" hidden={!data.isSaving && !data.saveRequested}>
                                    Settings were updated.
                                </Alert>
                            </div>
                        }
                        {!isSettings ?
                            (<Button
                                id="TNS_Integration_Back"
                                onClick={onPrev}
                                variant="outline-secondary"
                                className="mt-4"
                            >
                                Back
                            </Button>) : null
                        }
                        {
                            isStartup ?
                                (
                                    <Button variant="primary" type="submit">
                                        <LoadingIndicator loading={isFetching}>
                                            <span> Start exploring service </span>
                                        </LoadingIndicator>
                                    </Button>
                                )
                                :
                                (<Button
                                    id={!isSettings && 'TNS_Integration_Next'}
                                    className="mt-4"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSettings ? settingCannotSubmit : twilioFormCannotSubmit}
                                >
                                    <LoadingIndicator loading={isFetching || isSaving}>
                                        {isSettings ? 'Save' : 'Next'}
                                    </LoadingIndicator>
                                </Button>)
                        }
                    </div>
                </Form>
            </LoadingIndicator>
        </div>
    );
};

export default IntegrationOptions;

IntegrationOptions.propTypes = {
    isStartup: propTypes.bool,
    isSettings: propTypes.bool,
    onNext: propTypes.func,
    onPrev: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    fetchError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    saveError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    fetchFieldsError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    isFetching: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    isSaving: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    integrationOptions: propTypes.object,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    fields: propTypes.array,
};

IntegrationOptions.defaultProps = {
    isSettings: true,
    onNext: () => {},
    onPrev: () => {},
};
