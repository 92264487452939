import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import './TextReplySettings.scss';
import { connect } from 'react-redux';
import {
    getTwilioSettingsAcceptIncoming,
    getTwilioSettingsSaveError,
    getTwilioSettingsSaveState } from '../../../../reducers/settingsReducer';
import { useToggleTwilioSettings } from './hooks/useToggleTwilioSettings';
import LoadingIndicator from '../../../LoadingIndicator';
import { externalResources } from 'mp-constants';

const TextReplySettings = ({ textReplyEnabled, isSaving, error }) => {
    const [toggled, toggleTwilioSettings] = useToggleTwilioSettings(textReplyEnabled);
    const toggleWithDebounce = debounce(toggleTwilioSettings, 200);

    return (
        <div className="mainContent">
            <h1 className="mb-4">
                Text reply settings
            </h1>
            <div className="text-reply-settings__description">
                <p>
                    Allow users to reply to your text messages or disable this option.
                </p>
                <p className="font-weight-bold">
                    Note: Receiving text replies from users results in
                    {' '}
                    <a href={externalResources.wildApricot.help_ConsentManagement} target="_blank" rel="noreferrer">
                        extra charges
                    </a>
                    {' '}
                    to your account.
                </p>
            </div>
            { error ?
                (<Alert variant="danger d-flex align-items-center" className="text-reply-settings__alert_disabled">
                    <div className="pr-3">
                        Settings failed to save
                    </div>
                </Alert>)
                :
                isSaving ?
                    (
                        <div className="pb-4">
                            <LoadingIndicator
                                loading={isSaving}
                                loadingText="Saving..."
                            />
                        </div>
                    )
                    : toggled ?
                        (<Alert variant="warning" className="text-reply-settings__alert_enabled mb-4">
                            Text replies are currently enabled for this account. Messaging fees apply to each response.
                        </Alert>)
                        :
                        (<Alert variant="info" className="text-reply-settings__alert_disabled mb-4">
                            Text replies are currently disabled for this account.
                        </Alert>)
            }
            <div className="d-flex">
                <span>
                    Allow users to reply to text messages
                </span>
                <Form.Check
                    className="ml-2"
                    type="switch"
                    id="text-reply-settings-switch"
                    label
                    checked={toggled}
                    onChange={toggleWithDebounce}
                />
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        textReplyEnabled:getTwilioSettingsAcceptIncoming(state),
        isSaving: getTwilioSettingsSaveState(state),
        error: getTwilioSettingsSaveError(state),
    };
}

export default connect(
    mapStateToProps,
)(TextReplySettings);
