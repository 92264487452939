import React from 'react';
import propTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import twilioStatuses from '../../../constants/twilioStatuses';
import SplashScreenLoader from '../../SplashScreenLoader/SplashScreenLoader';
import RegisterNotification from './RegisterNotification';
import elevioHelpArticles from '../../../constants/elevio-help-articles';
import { openHelpArticle } from '../../HelpCenter/src/utils/elevio/util/elevio-functions';
import { getTwilioSubscription } from '../../../reducers/settingsReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { externalResources } from 'mp-constants';
function NotificationTemplate({ children, variant }) {
    return (
        <div className="globalNotification">
            <Alert variant={variant} className="pb-3">
                {children}
            </Alert>
        </div>
    );
}

NotificationTemplate.propTypes = {
    children: propTypes.node.isRequired,
    variant: propTypes.string,
};

NotificationTemplate.defaultProps = {
    variant: 'danger',
};

function GlobalNotification({ me, currentUserError, fetchingCurrentUser, wizardComplete }) {
    const { Status } = useSelector(getTwilioSubscription) || {};
    const { pathname } = useLocation();

    const showConsentArticle = () => {
        openHelpArticle(elevioHelpArticles.TNS.CONSENT.id);
    };

    if (fetchingCurrentUser) {
        return (null);
    }

    if (currentUserError) {
        return (
            <SplashScreenLoader
                error
                errorMessage={<p className="mt-3">TNS service currently unavailable. Please try again later.</p>}
            />
        );
    }

    if (!me) {
        return null;
    }

    if (me.account.hasSubscription
        && me.account?.lastPaimentSucceeded === false) {
        return (
            <NotificationTemplate variant="warning">
                <span>
                    Last payment failed. Please
                    {' '}
                    <Link to="/settings/subscription">update credit card</Link>
                    {' '}
                    on file to avoid service interruption.
                </span>
            </NotificationTemplate>
        );
    }

    if (me.account.accountStatus === 'suspended') {
        return (
            <NotificationTemplate>
                <span>
                    <b>Your TNS account is suspended. </b>
                    {' '}
                    This may be due to nonpayment or a policy violation.
                    {' '}
                    For more information, view
                    {' '}
                    <div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                        <dfn
                            style={{ cursor: 'pointer', borderBottom: '1px dashed' }}
                            onClick={() => openHelpArticle(elevioHelpArticles.TNS_FAQS.ACCOUNT_SUSPENDED.id)}
                        >
                            Why is my TNS account suspended?
                        </dfn>
                    </div>
                </span>
            </NotificationTemplate>
        );
    }
    if ((Status === twilioStatuses.in_review || Status === twilioStatuses.pending_review)
        && pathname.toLowerCase() !== '/subscription-wizard') {
        return (
            <NotificationTemplate variant="warning">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                        size="2x"
                        icon={faTriangleExclamation}
                        className="pr-2 mr-4"
                    />
                    <span>
                        Your text messaging account registration is
                        <a href={externalResources.wildApricot.help_Registering} target="_blank" 
                            rel="noopener noreferrer">pending approval</a>.
                        Message functionality and performance may be limited prior to approval.
                    </span>
                </div>
            </NotificationTemplate>
        );
    }

    if ((Status === twilioStatuses.in_review || Status === twilioStatuses.pending_review)
        && pathname.toLowerCase() !== '/subscription-wizard') {
        return (
            <NotificationTemplate variant="warning">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                        size="2x"
                        icon={faTriangleExclamation}
                        className="pr-2 mr-4"
                    />
                    <span>
                        Your text messaging account registration is 
                        <a href={externalResources.wildApricot.help_Registering} target="_blank" 
                            rel="noopener noreferrer">pending approval</a>.
                        Message functionality and performance may be limited prior to approval.
                    </span>
                </div>
            </NotificationTemplate>
        );
    }

    if ((Status === twilioStatuses.in_review || Status === twilioStatuses.pending_review )
        && wizardComplete && pathname.toLowerCase() !== '/subscription-wizard') {
        return (
            <NotificationTemplate variant="info">
                <span>
                    Registration of your account and virtual phone number is in process. This may take
                    up to several days. You will be able to start messaging as soon as registration is complete.
                </span>
            </NotificationTemplate>
        );
    }

    if ((Status === twilioStatuses.twilio_rejected)
        && pathname.toLowerCase() !== '/subscription-wizard'
        && pathname.toLowerCase() !== '/settings/account-details') {
        return (
            <NotificationTemplate variant="danger">
                <span>
                    Your Text Notification Service application was rejected because the business information you
                    provided did not match official Tax ID/EIN records. Please
                    {' '}
                    <Link to="/settings/account-details">edit your application</Link>
                    {' '}
                    and resubmit it for review.
                </span>
            </NotificationTemplate>
        );
    }

    if ((Status === twilioStatuses.campaign_rejected)
        && pathname.toLowerCase() !== '/subscription-wizard') {
        return (
            <NotificationTemplate variant="danger">
                <span>
                    Your Text Notification Service application was rejected because we couldn’t verify that the
                    mandatory consent field is publicly available for users. Please
                    {' '}
                    <Link to="/settings/integration-settings">change the settings</Link>
                    {' '}
                    for the consent field and resubmit the application.

                    <Button onClick={showConsentArticle} variant="link">
                        <FontAwesomeIcon icon={faQuestionCircle} className="text-info" size="lg" />
                    </Button>
                </span>
            </NotificationTemplate>
        );
    }

    const billingPlan = (me.account.billingPlan || '').toLowerCase();

    if (!me.account.hasSubscription && !me.account.hasTwilioAccount
        && !(billingPlan === 'free' || billingPlan === 'trial')
        && pathname.toLowerCase() !== '/subscription-wizard'
        && pathname.toLowerCase() !== '/wa-signup'
        && (Status !== twilioStatuses.in_review && Status !== twilioStatuses.pending_review)) {
        return (<RegisterNotification />);
    }

    if ((billingPlan === 'free' || billingPlan === 'trial')
        && pathname.toLowerCase() !== '/subscription-wizard') {
        return (
            <NotificationTemplate>
                <span>
                    Your WildApricot account is on
                    {' '}
                    {billingPlan.toUpperCase()}
                    {' '}
                    billing plan. To protect from spammers,

                    WildApricot&apos;s text messaging serves only paid WildApricot accounts.
                    {/* eslint-disable-next-line max-len */}
                    You still can explore the system, while some functions of WildApricot&apos;s text messaging are disabled.
                </span>
            </NotificationTemplate>
        );
    }

    return null;
}

GlobalNotification.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    me: propTypes.object,
    // eslint-disable-next-line react/require-default-props
    currentUserError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    twilioSubscriptionError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    hasSubscription: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    fetchingCurrentUser: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    wizardComplete: propTypes.bool,
};

function mapStateToProps(state) {
    return {
        me: state.auth.me,
        authFailed: state.auth.authFailed,
        currentUserError: state.auth.currentUserError,
        fetchingCurrentUser: state.auth.fetchingCurrentUser,
        wizardComplete: state.subscriptionWizard.wizardCompleted || false,
    };
}

export default connect(
    mapStateToProps,
)(GlobalNotification);
